<template>
  <div class="home">
    <TopNavigation route="home"/>
    <el-carousel :interval="5000" :height="mobile?'350px':'700px'" arrow="always">
      <el-carousel-item v-for="item in homeImage" :key="item" class="play_turns">
        <img :src="item" class="play_turn"/>
      </el-carousel-item>
    </el-carousel>
    <el-row class="body">
      <el-col :span="24">
        <div class="section-title">
          <h2>Filecoin <span>满算力</span></h2>
        </div>
      </el-col>
      <el-col :span="4" v-if="!mobile"></el-col>
      <el-col :span="mobile?24:7">
        <div class="single-product">
					<div class="single-product-image">
						<figure>
							<img src="@/assets/images/commodity1.png" class="popular-products">
						</figure>
					</div>
					<div class="single-product-bottom-bar">
						<h5>
							<a href="#">算力高速封装3T  独立存储525T</a>
						</h5>
            <span class="single-product-content">
              基于前沿实践设计模型的计算存储分布式 /定制超强多路挖矿主板 内置创新技术：IO路径优化、多级智能缓存、深度代码调优 /AMD 线程霄龙处理器/NVIDIA 3090挖矿专用显卡 /西数荣耀企业级硬盘 采用T3+级别机房托管，专业运维团队
            </span>
					</div>
				</div>
      </el-col>
      <el-col :span="2" v-if="!mobile"></el-col>
      <el-divider v-if="mobile"></el-divider>
      <el-col :span="mobile?24:7">
        <div class="single-product">
					<div class="single-product-image">
						<figure>
							<img src="@/assets/images/commodity2.png" class="popular-products">
						</figure>
					</div>
					<div class="single-product-bottom-bar">
						<h5>
							<a href="#">荣耀存储数据 3000T 集群</a>
						</h5>
            <span class="single-product-content">
              多维一体超大型集群：算力机集群多线程高速封装数据，软件和硬件紧密连接、分层架构、彼此协调，然后在多台服务器中部署相同应用及服务，以共同完成某项任务。存储机集群安全稳定冗余数据，通过将数据分布到集群中各节点的存储机中存储。软件和硬件紧密连接、分层架构、彼此协调，构成ipfs集群。
            </span>
					</div>
				</div>
      </el-col>
      <el-col :span="4" v-if="!mobile"></el-col>
      <el-col :span="24" class="home-dynamic">
        <div class="section-title">
          <h2>热门动态</h2>
        </div>
      </el-col>
      <el-col :span="24" class="dynamic-blank"></el-col>
      <el-col :span="mobile?24:12" v-for="item in articleList" :key="item.published" :class="getPhoneCss('dynamic-item-bag')">
        <div class="dynamic-item">
          <div :class="getPhoneCss('dynamic-time')">
            <p>
              {{mobile?timestampToYear(item.published)+"-"+timestampToMonth(item.published):timestampToYear(item.published)}}
            </p>
            <span>
              {{mobile?"":timestampToMonth(item.published)}}
            </span>
          </div>
          <div class="vertical-divider"></div>
          <div class="dynamic-substance">
            <a :href="item.uri"  target="_Blank" class="article_uri">{{item.title}}</a><br>
            <span>
              {{item.abstract}}...
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="24" class="dynamic-blank"></el-col>
    </el-row>
    <FootHonour />
  </div>
</template>

<script>
import TopNavigation from '@/components/top_navigation.vue';
import FootHonour from '@/components/foot_honour.vue'
import {getArticle} from '@/util/http/api';
import { isMobile } from '@/util/adaptation_tool.js';

export default {
  name: 'Home',
  data(){
    return {
      page: 1,
      size: 6,
      total: 0,
      articleList: [],
      homeImage: [],
      mobile: false
    }
  },
  created(){
    this.getArticleList(this.page,this.size);
    //添加轮播图片
    this.homeImage = [
      require("@/assets/images/glory_storage.png"),
      require("@/assets/images/ipfs.jpeg")
    ]
    if(isMobile()){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
  },
  components: {
    TopNavigation,
    FootHonour
  },
  methods: {
    navigationTo(path){
      this.$router.push(path);
    },
    changePage(val){
      this.getArticleList(val,this.size);
    },
    getArticleList(page,size){
      var ths = this;
      getArticle(page,size).then(
        (res) => {
          console.table(res);
          if(res.errCode==200){
            ths.total = res.data.total;
            ths.articleList = res.data.items;
          }
        }
      );
    },
    timestampToTime (time) {//转换时间戳
      var date = new Date(time*1000);
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = date.getDate() + ' ';
      var h = date.getHours() + ':';
      var m = date.getMinutes()
      return Y+M+D+h+m;
    },
    timestampToYear (time) {
      var date = new Date(time*1000);
      var y = date.getFullYear();
      return y;
    },
    timestampToMonth (time) {
      var date = new Date(time*1000);
      var m = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var d = date.getDate() + ' ';
      return m+d;
    },
    //临时移动端适配方案 获取手机端的css
    getPhoneCss(className) {
      if(!this.mobile){
          return className;
      }else{
          return className+"-m";
      }
    }
  },
}
</script>

<style  src="@/views/css/home.css" scoped/>