<template>
  <router-view>
  </router-view>
</template>

<script>
export default {
  created(){
    //this.$router.push('/');
  },
}
</script>

<style>
html {
  height: 100%;
}
body {
  height: 100%;
}
#app {
  height: 100%;
}
</style>
