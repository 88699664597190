<template>
  <div class="home">
    <TopNavigation route="dynamic"/>
    <el-row class="body">
      <el-col :span="4"></el-col>
      <el-col :span="mobile?24:16" class="journalism">
        <div class="recommend">
          文章推荐
        </div>
        <div v-for="item in articleList" :key="item.published" class="listfor">
          <el-divider></el-divider>
          <a :href="item.uri" target="_Blank">
            <a :href="item.uri"  target="_Blank" :class="getPhoneCss('article_title')">{{item.title}}</a>
            <div :class="getPhoneCss('article')">
              <div :class="getPhoneCss('subtext')">
                {{item.abstract}}...
                <div :class="getPhoneCss('article_time')" v-if="!mobile">
                  {{timestampToTime(item.published)}}
                </div>
              </div>
              <div v-if="item.image">
                <el-image
                  :class="getPhoneCss('article_image')"
                  :src="item.image"
                  fit="cover" 
                  >
                </el-image>
              </div>     
              <div :class="getPhoneCss('article_time')" v-if="mobile">
                {{timestampToTime(item.published)}}
              </div>        
            </div>
          </a>
        </div>
        <div class="page_turn">
          <el-pagination background layout="prev, pager, next" :total="total" :current-page="page" @current-change="changePage" :page-size="size">
          </el-pagination>
        </div>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
import TopNavigation from '@/components/top_navigation.vue';
import {getArticle} from '@/util/http/api';
import { isMobile } from '@/util/adaptation_tool.js';

export default {
  name: 'Home',
  data(){
    return {
      page: 1,
      size: 10,
      total: 0,
      articleList: [],
      mobile: false
    }
  },
  created(){
    this.getArticleList(this.page,this.size);
    if(isMobile()){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
  },
  components: {
    TopNavigation,
  },
  methods: {
    navigationTo(path){
      this.$router.push(path);
    },
    changePage(val){//更改页码刷新文章
      this.getArticleList(val,this.size);
    },
    getArticleList(page,size){//获取文章
      var ths = this;
      getArticle(page,size).then(
        (res) => {
          console.table(res);
          if(res.errCode==200){
            ths.total = res.data.total;
            ths.articleList = res.data.items;
          }
        }
      );
    },
    timestampToTime (time) {//转换时间戳
      var date = new Date(time*1000);
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = date.getDate() + ' ';
      var h = date.getHours() + ':';
      var m = date.getMinutes()
      return Y+M+D+h+m;
    },
    //获取手机端的css
    getPhoneCss(className) {
      if(!this.mobile){
          return className;
      }else{
          return className+"-m";
      }
    }
  },
}
</script>

<style  src="@/views/css/dynamic.css" scoped/>