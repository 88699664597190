<template>
  <div class="about">
    <TopNavigation route="about"/>
    <div class="about_mine">
      <br><br>
      关于我们
    </div>
    <el-row class="row1">
      <el-col :span="mobile?2:5"></el-col>
      <el-col :span="mobile?20:4" class="data_card">
        <div class="card_title">
          专业的团队
        </div>
        <div class="card_text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳数客网络科技有限责任公司专注于分布式存储、分布式计算、分布式数据库研发的高新技术企业，拥有多名国家高新技术研发成员和多年的从业经验。
        </div>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :span="2" v-if="mobile"></el-col>
      <el-col :span="mobile?20:4" class="data_card">
        <div class="card_title">
          优质的服务
        </div>
        <div class="card_text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7×24小时全天候服务，提供线上微信以及电话极速联系，关注客户的需求，快速解决问题，维护客户的利益，坚持诚信服务的原则。
        </div>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :span="2" v-if="mobile"></el-col>
      <el-col :span="mobile?20:4" class="data_card">
        <div class="card_title">
          完善的机房
        </div>
        <div class="card_text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;T3+级IDC机房，可并行维护的数据中心拥有冗余容量组件，以及多个独立分配路径来为关键系统提供服务，所有设备均为双电源供电，储备 12 个小时的现场燃料存储，更加安全且稳定。
        </div>
      </el-col>
      <el-col :span="5"></el-col>
    </el-row>
    <div style="height:50px" v-if="mobile"></div>
    <FootHonour />
  </div>
</template>

<script>
import TopNavigation from '@/components/top_navigation.vue';
import FootHonour from '@/components/foot_honour.vue'
import { isMobile } from '@/util/adaptation_tool.js';

export default {
  name: 'About',
  data(){
    return {
      mobile: false
    }
  },
  components: {
    TopNavigation,
    FootHonour
  },
  created(){
    if(isMobile()){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
  },
}
</script>

<style  src="@/views/css/about.css" scoped/>