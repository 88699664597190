<template>
  <div class="starfield" v-show="!isWechatWeb">
    <TopNavigation route="download"/>
    <div class="static"></div>
    <div class="moving-1"></div>
    <div class="moving-2"></div>
    <div class="moving-3"></div>
    <div :class="getPhoneCss('demo-image')">
      <img :src="url" class="img_logo" alt="荣耀存储">
    </div>
    <div class="download">
      <el-button type="primary" @click="download(android)">Android 下载</el-button>
      <el-button type="primary" @click="download(ios)">IOS 下载</el-button>
    </div>
    <div style="bottom:80px; padding-top: 2.5rem; margin:0 auto;">
      <el-button type="text" @click="register()">注 册</el-button>
    </div>
  </div>
  <!-- 用浏览器打开 -->
  <div v-show="isWechatWeb">
    <!--这里是要展示的内容层-->
    <div class="dialog-contant">
      <div class="dialog">
        <img class="correct" src="@/assets/images/open_browser.png"/>
      </div>
    </div>
    <!--这里是半透明背景层-->
    <div class="over">
    </div>
  </div>
</template>

<script>
import TopNavigation from '@/components/top_navigation.vue'
import { isMobile } from '@/util/adaptation_tool.js';

export default {
  data() {
    return {
      android: 'https://sfkil.oss-cn-shenzhen.aliyuncs.com/apps/skfil.apk',
      // ios: 'itms-services://?action=download-manifest&url=https://skfil.com/assets/ios.plist',
      ios: 'https://testflight.apple.com/join/FXo4F0hS',
      url: require("../assets/images/logo.png"),
      mobile: false,
      isWechatWeb: false
    };
  },
  components: {
    TopNavigation
  },
  methods: {
    download(uri) {
      document.location.href = uri;
    },
    register() {
      this.$router.push('/signin')
    },
    //临时移动端适配方案 获取手机端的css
    getPhoneCss(className) {
      if(!this.mobile){
          return className;
      }else{
          return className+"-m";
      }
    }
  },
  created(){
    //判断是否手机浏览器
    if(isMobile()){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
    //判断是否是微信浏览器
    let isWeiXin = () => { return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1 }
    if (isWeiXin()) {
      this.isWechatWeb = true;
    } else {
      this.isWechatWeb = false;
    }
  },
};
</script>

<style>
.download {
  margin-top: 1.25rem;
}
.demo-image {
  margin: 0 auto;
  margin-top: 18.75rem;
  text-align: center;
  width: 6.25rem;
}
.demo-image-m {
  margin: 0 auto;
  margin-top: 50%;
  text-align: center;
  width: 6.25rem;
} 
.img_logo{
  border-radius: .3125rem;
  width: 6.25rem;
}
.correct {
    width: 100%;
    z-index: 1001;
}
.over {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    top: 0;
    z-index: 999;
    background-color: #F3F3F3;
    overflow-x: hidden;
    overflow-y: hidden;
}
@import url("./css/style.css");
</style>
