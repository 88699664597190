<template>
  <div id="nav">
    <el-row>
      <el-col :span="12" v-if="!isMobile()">
        <div :class="state==0?'navigation0 honour':'navigation1 honour'" @click="navigationTo('/')">
            Honour
        </div>
      </el-col>
      <el-col :span="isMobile()?24:12">
        <div :class="state==0?'navigation0':'navigation1'">
          <button :class="state==0?route=='home'?'button-47':'button-48':route=='home'?'button-49':'button-50'" role="button" @click="navigationTo('/')"><span class="text">首页</span></button>
          <button :class="state==0?route=='dynamic'?'button-47':'button-48':route=='dynamic'?'button-49':'button-50'" role="button" @click="navigationTo('/dynamic')"><span class="text">动态</span></button>
          <button :class="state==0?route=='download'?'button-47':'button-48':route=='download'?'button-49':'button-50'" role="button" @click="navigationTo('/download')"><span class="text">下载</span></button>
          <button :class="state==0?route=='about'?'button-47':'button-48':route=='about'?'button-49':'button-50'" role="button" @click="navigationTo('/about')"><span class="text">关于我们</span></button>
        </div>
      </el-col>
    </el-row>  
  </div>
</template>

<script>
import router from "../router/index.js";
export default {
  name: 'TopNavigation',
  data(){
    return {
      svroll: 0,
      state: 0, //0：普通状态背景为蓝色    1：特殊状态背景为透明
    };
  },
  props: {
    route: String
  },
  created () {
    console.log(this.route);
    if(this.isMobile()){
      this.state = 0;
    }else{
      if(this.route == "home"){
        this.listenerFunction();
        this.state = 1;
      }else{
        this.state = 0;
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener("scroll", this.listenerFunction);
  },
  methods: {
    navigationTo(path){
      router.push(path);
    },
    //监听滚动条滚动
    listenerFunction(e) {
      document.addEventListener('scroll', this.handleScroll, true);
    },
    handleScroll () {
      //如果滚动条滚动超过10像素就变换状态
      if(window.pageYOffset>10){
        this.state = 0;
      }else{
        this.state = 1;
      }
    },
    //手机端适配  在class后加上-m
    getPhoneCss(className) {
      if(this.mobile){
        return className;
      }else{
        return className+"-m";
      }
    },
    //判断是否手机端
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|MicroMessenger)/i
      )
      return flag!=null;
    },
  }
}
</script>

<style src="@/components/css/top_navigation.css" scoped />

