import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Download from '../views/Download.vue';
import Registered from '../views/Registered.vue';
import Signin from '../views/Registered.vue';
import Dynamic from '../views/Dynamic.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/registered',
    name: 'Registered',
    component: Registered
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin
  },
  {
    path: "/dynamic",
    name: "Dynamic",
    component: Dynamic
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router
