<template>
  <el-row :class="getPhoneCss('foot')">
      <el-col :span="5"></el-col>
      <el-col :span="mobile?24:6">
        <div class="foot_title">
          联系我们
        </div>
        <div class="text_content">
          商务： 440-3011-1107-6549
        </div>
        <div class="text_content">
          邮箱： 812232692@qq.com
        </div>
        <div class="text_content">
          地址： 深圳市盐田区海山街道鹏湾社区海景二路科技大厦6A-757
        </div>
        <div class="text_content">
          公司名称： 深圳数客网络科技有限责任公司
        </div>
      </el-col>
      <el-col :span="2">
      </el-col>
      <el-col :span="mobile?24:6">
        <div class="foot_title">
          关注我们
        </div>
        <div class="focus">
          <p>公众号</p>
          <img src="@/assets/images/official_account.jpg" class="qrcode"/>
        </div>
        <div class="focus">
          <p>百家号</p>
          <img src="@/assets/images/baidu_qrcode.png" class="qrcode"/>
        </div>
      </el-col>
      <el-col :span="5"></el-col>
      <el-col :span="5"></el-col>
      <el-col :span="mobile?24:14">
        <el-divider></el-divider>
      </el-col>
      <el-col :span="5"></el-col>
      <el-col :span="24" class="keep-record">
          <a href="https://beian.miit.gov.cn/" target="_Blank">网站备案号：粤ICP备2021075487号</a>
      </el-col>
      <el-col :span="24" class="foot_copyright">
        Copyright ©2021 荣耀云
      </el-col>
    </el-row>
</template>

<script>
import { isMobile } from '@/util/adaptation_tool.js';

export default {
  name: 'Home',
  data(){
    return {
      mobile: false
    }
  },
  created(){
    if(isMobile()){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
  },
  methods: {
    //获取手机端的css
    getPhoneCss(className) {
      if(!this.mobile){
          return className;
      }else{
          return className+"-m";
      }
    }
  },
}
</script>

<style  src="@/components/css/foot_honour.css" scoped/>